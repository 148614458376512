<template>
  <b-row class="m-0">
    <div 
      v-if="Object.keys(marker_selected).length > 0" 
      class="position-relative w-100" 
    >
      <b-badge class="badge-map badge-selected">{{marker_selected.category}}</b-badge>
      <b-avatar :src="getImage(marker_selected)" icon="house-door-fill" square class="avatar-selected-map"/>
      <div class="p-2 border-bottom">
        <h2 class="d-block">{{marker_selected.name}}</h2>
        <p>{{marker_selected.address}}</p>
        <p class="text-center">IDOH: {{marker_selected.external_id ? marker_selected.external_id : marker_selected.id}}</p>
      </div>
      <div class="p-2 d-flex justify-content-center align-items-center border-bottom ">
        <span class="margin-right-05 color-gray-button">{{$t('search.add_to')}}:</span>
        <dropdown-campaigns
          :campaigns="campaigns" 
          :influencers="[marker_selected.uuid]"
          :multiple_added="false"
          :is_ooh="true"
          :campaigns_influencer="location_in"
          :disabled="false"
          @update_drop="updateDropdownCampaigns"
          class="mr-1 mb-1 mb-sm-0"
          :key="update_drop_campaigns"
        />
      </div>
    </div>

    <b-col cols="12">
      <b-row>
        <b-col class="col-6 d-flex align-items-stretch mt-1" v-for="(marker, index) in markers" :key="index">
          <b-card 
            class="card-marker-map"
            @click="zoomMarker(marker)"
          >
            <b-badge class="badge-map">{{marker.category}}</b-badge>
            <b-avatar :src="getImage(marker)" icon="house-door-fill" square class="avatar-location-map"/>
            <b-card-text class="p-05">
              <strong class="d-block">{{marker.name}}</strong>
              <p class="">{{marker.address}}</p>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BBadge,
  BAvatar,
  BCardText,
} from 'bootstrap-vue';
import dropdownCampaigns from '@/views/campaings/dropdownCampaigns.vue';
import { isUrl, getAssetUrl } from '@/libs/utils/urls';

export default {
  name: 'listLocations',
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BAvatar,
    BCardText,
    dropdownCampaigns,
  },
  props: {
    markers: {
      type: Array,
    },
    marker_selected: {
      type: Object
    },
    campaigns: {
      type: Array
    },
    location_in: {
      type: Array
    },
    update_drop_campaigns: {
      type: Boolean
    }
  },
  methods: {
    getImage(marker_selected) {
      if (marker_selected.internal_image) return isUrl(marker_selected.internal_image) ? marker_selected.internal_image : getAssetUrl(marker_selected.internal_image)
      return marker_selected.external_image
    },
    zoomMarker(marker) {
      this.$emit('zoom_marker', marker);
    },
    updateDropdownCampaigns() {
      this.$emit('update_location_in', this.marker_selected.uuid)
    },
  },
}
</script>

<style>
.avatar-selected-map {
  width: 100% !important;
  height: 288px;
  object-fit: cover;
  background-color: #dcdcdc;
}
.card-marker-map {
  border: 1px solid #ccc;
  width: 100%;
  position: relative;
}
.card-marker-map > .card-body {
  padding: 0;
}
.card-marker-map:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  cursor: pointer;
}
.avatar-location-map {
  width: 100%;
  height: 120px;
  object-fit: cover;
  background-color: #dcdcdc;
}
.spinner-list-locations-container {
  width: 100% !important;
}
.spinner-list-locations {
  width: 40px;
  height: 40px;
  color: rgb(181, 181, 181);
}
</style>